<template>
  <Modal ref="modal" :class="`age-advice ad-confirm ${is_home && '__home'}`" :lock="$loading" @close="answerCancel" :settings="settings">
    <div class="age-advice-title" slot="header">
      <span class="icon18">18+</span>
      <p>{{ $locale["words"]["adult_content"] }}</p>
    </div>
    <div class="age-advice-content">
      <div class="age-advice-content-title">{{ $locale["words"]["adult_content"] }}</div>
      <div class="age-advice-content-message">
        <p>{{ $locale["words"]["age_advice"] }}</p>
        <p>{{ $locale["words"]["and"] }} {{ $locale["words"]["terms_agree"] }}</p>
      </div>
      <div class="age-advice-content-legal">
        <LandingFooterLegal />
      </div>
      <div class="age-advice-content-options">
        <Confirm :confirm="confirm" @cancel="answerCancel" @accept="answerAccept" />
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  props: ["is_home"],
  components: {
    LandingFooterLegal: () => import("./LandingFooterLegal.vue"),
  },
  data: function() {
    return {
      settings: {
        opened: true,
        class: "view menu bottom mk_head",
        animation: "fadeIn",
      },
      confirm: {
        cancel: {
          label: this.words("cancel"),
          icon: "times",
        },
        accept: {
          label: this.words("accept"),
          icon: "check",
        },
      },
    };
  },
  methods: {
    answerCancel: function() {
      this.$emit("cancel");
      if (this.is_home) return;
      this.$store.commit("setAgeAccepted", false);
    },
    answerAccept: function() {
      this.$emit("accept");
      if (this.is_home) return;
      this.$store.commit("setAgeAccepted", true);
    },
    words: function(key) {
      return this.$store.state.locale.words[key];
    },
  },
};
</script>

<style lang="scss">
.age-advice {
  &-title {
    display: flex;
    align-items: center;
    padding: $mpadding;
    gap: $mpadding;
    .icon18 {
      @include Flex(inherit, center, center);
      width: $mpadding * 3.5;
      height: $mpadding * 3.5;
      background-color: #fff;
      border-radius: 50%;
      color: $primary_color;
      font-size: 150%;
      font-weight: bold;
    }
  }
  &.ad-confirm .modal {
    &-card {
      background-color: var(--dcolor);
      background-image: var(--sec-gradient);
      color: #fff;
      min-height: 256px;
      max-width: $pocket_width;
    }
    &-card-header {
      background-color: rgba(0, 0, 0, 0) !important;
      border-bottom: none !important;
      color: #fff;
    }
  }

  .modal-card-content {
    padding: 0 $mpadding !important;
  }

  .modal-card-header-right {
    .button .iconic {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  &-content {
    text-align: center;
    padding: $mpadding 0 0 0;
    &-title {
      font-size: 180%;
      margin: 0 0 $mpadding 0;
    }
    &-message {
      margin: 0 0 $mpadding 0;
    }
    &-legal {
      padding: $mpadding 0;
      color: #fff;
      .footer-legal {
        justify-content: center;
        .iconic svg {
          path,
          polygon {
            fill: #fff;
            stroke: #fff;
          }
        }
      }
    }
    &-options {
      padding: $mpadding 0;
      .confirm .confirm-button {
        padding: $mpadding/1.5;
        box-shadow: #0000004d 0px 1px 0px;
        text-shadow: #ffffff66 0px 1px;
        &.__cancel {
          background-color: #fff;
        }
        &.__accept {
          background: var(--third-gradient);
          color: rgb(68, 31, 0);
        }
      }
    }
  }
  &.__home {
    @include Glass(rgba(0, 0, 0, 0.2), 0.2, 10px);
    .modal-card-header-right .button {
      display: none;
    }
  }
}
</style>
